import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const CheckBoxWrapper = styled.div`
  background: ${props => props.selected ? 'rgba(79, 89, 240, 1)' : 'rgba(255,255,255,0.1)'};
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 2px;
  width: 15px;
  height: 15px;
  opacity: ${props => props.disabled && !props.selected ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'initial'};
  cursor: ${props => props.selected ? 'initial' : 'pointer'};
`;

export default function Checkbox({ label, options, value, onClick, labelStyle, ...checkboxProps }) {
    
    return <Row>
        <CheckBoxWrapper onClick={onClick} {...checkboxProps} />
        <span style={labelStyle}>{label}</span>
      </Row>

}

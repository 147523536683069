/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal } from './modal';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import Select from 'react-dropdown-select';
import useAuth from '../utils/useAuth';
import loadingIcon from '../images/3-dots-move.svg'
import { useContext } from 'react';
import { TableContext } from './table';

const Box = styled.div`
    border-radius: 24px;
    background: white;
`;

const TopSection = styled.div`
    padding: 24px 40px;
    border-bottom: 1px solid rgba(219, 219, 219, 1);
`;

const FormSection = styled(Column)`
    padding: 32px 40px;
    gap: 20px;
    width: 400px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;

const InputWrapper = styled(Column)`
    label {
        color: rgba(91, 99, 125, 1);
        font-size: 15px;
        margin-bottom: 10px;
    }
    input {
        font-family: 'Satoshi-Regular';
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        font-size: 16px;
        outline: none;

        &:disabled {
            opacity: 0.5
        }
    } 
`;

const DropdownInputWrapper = styled(Column)`
    label {
        color: rgba(91, 99, 125, 1);
        font-size: 15px;
        margin-bottom: 10px;
    }
    * {
        outline: none;
        font-family: 'Satoshi-Regular';
    }
    .react-dropdown-select {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        outline: none;
    } 
    .react-dropdown-select-input{
        font-size: 16px;
        padding: 0px;
        font-family: 'Satoshi-Regular';
    }
`;

const Input = ({ label, ...inputProps }) => {

    return <InputWrapper>
        <label>{label}</label>
        <input {...inputProps} />
    </InputWrapper>

}

const DropdownInput = ({ label, options, value, onChange, ...inputProps }) => {
    
    return <DropdownInputWrapper>
        <label>{label}</label>
        <Select options={options} values={value ? [value] : []} 
        onChange={(e) => {
            onChange(e[0])
        }}
        {...inputProps} />
    </DropdownInputWrapper>

}


const Button = styled.div`
    color: rgba(79, 89, 240, 1);
    cursor: pointer;
    font-size: 16px;
`;

const PrimaryButton = styled.div`
    background: rgba(79, 89, 240, 1);
    color: white;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;

const Error = styled.div`
    color: red;
    font-size: 15px;
`;

const sortOptions = [
    { label: 'Ascending', value: 'ASC'},
    { label: 'Descending', value: 'DESC'},
]

function removeNonNumericAndDot(inputString) {
    return inputString.replace(/[^0-9.]/g, '');
}

function evaluateNumberString(numberString) {
    let cleanedString = numberString.replace(/[^0-9.]/g, '');
    if (!/^\d*\.?\d+$/.test(cleanedString)) {
        return false
    }
    let result = parseFloat(cleanedString);
    if (isNaN(result)) {
        return false
    }
    return true
}


export default function ColumnQuery({ 
    setVisible,
    emailDisabled,
    setFilters, 
    dateField,
    disableSorting,
    disableQuery,
    title,
    numberOnly,
    ...column
}) {

    const { id, fetchOptions } = column;
    const { 
        fieldQuery, 
        setSkip, 
        setFieldQuery, 
        fieldSort, 
        setFieldSort,
        fetchKits
    } = useContext(TableContext)

    const [options, setOptions] = useState(null);
    const [query, setQuery] = useState(fieldQuery[id] || '');
    const [sort, setSort] = useState(fieldSort[id] || {});
    const [error, setError] = useState(null);
    const { claims } = useAuth()
    const [loading, setLoading] = useState(true);
    

    const _fetchOptions = async () => {
        if (fetchOptions) setOptions(await fetchOptions());
        setLoading(false)
    }
    
    useEffect(() => {
        if (claims) _fetchOptions()
    }, [claims, fetchOptions]);

    const cleanSort = () => {
        setFieldQuery((prev) => {
            delete prev[id];
            return prev;
        });
        setFieldSort((prev) => {
            delete prev[id]
            return prev;
        });
        fetchKits()
        setVisible(null)
    }

    const onConfirm = async () => {
        let changed = false;
        setError(null)
        
        if (query && numberOnly && !evaluateNumberString(query)) {
            setError('Invalid number');
            return;
        }

        if (fieldQuery[id] !== query) {
            setFieldQuery((prev) => {
                if (!query) {
                    delete prev[id]
                    return prev
                } else return {...prev, [id]: query}
            })
            changed = true
        };
        if (!!Object.keys(sort).length) {
            setFieldSort({ [id]: sort});
            changed = true
        }
        if (changed) setSkip(0)
        setVisible(null)
    }
    
    const disabled = !query && !sort;

    if (loading) return <Modal>
        <img src={loadingIcon} alt='loading' />
    </Modal>

    
    return <Modal onClickOutside={() => setVisible(null)}>
        <Box onClick={(e) => e.stopPropagation()}>
            {!loading && <>
            <TopSection>
                <Title>Query by {title}</Title>
            </TopSection>
            <FormSection>
                {(options && !disableQuery) && <DropdownInput label={title} options={options} value={query} onChange={(e) => setQuery(e)} />}
                {(!loading && !options && !disableQuery) && <Input label={title} type={dateField ? 'date' : ''} value={query} onChange={(e) => setQuery(numberOnly ? removeNonNumericAndDot(e.target.value) : e.target.value)} />}
                {!disableSorting && <DropdownInput label='Sort' options={sortOptions} value={sort} onChange={e => setSort(e)} />}
                <Row style={{ gap: 15, alignItems: 'center', justifyContent: 'flex-end', marginTop: 20 }}>
                    <Button onClick={() => cleanSort()}>Reset</Button>
                    <Error>{error}</Error>
                    <div style={{ flex: 1 }} />
                    <Button onClick={() => setVisible(null)}>Cancel</Button>
                    <PrimaryButton disabled={disabled} onClick={onConfirm}>Confirm</PrimaryButton>
                </Row>
            </FormSection>
            </>}
            
        </Box>
    </Modal>

}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Modal } from './modal';
import styled from 'styled-components';
import { Button, Column, Row } from './defaults';
import api from '../utils/api';
import { NotificationManager } from 'react-notifications';

const Box = styled.div`
    border-radius: 24px;
    background: white;
`;

const TopSection = styled.div`
    padding: 24px 40px;
    border-bottom: 1px solid rgba(219, 219, 219, 1);
`;

const FormSection = styled(Column)`
    padding: 32px 40px;
    gap: 20px;
    width: 400px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;

const InputWrapper = styled(Column)`
    input {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        font-size: 16px;
        outline: none;
        font-family: 'Satoshi-Medium';

        &:disabled {
            opacity: 0.5
        }
    } 
`;

const Input = React.forwardRef(({ label, ...inputProps }, ref) => {
    return  <InputWrapper>
        <input ref={ref} {...inputProps} />
    </InputWrapper>
})

const ConfirmButton = styled(Button)`
    background: rgba(79, 89, 240, 1);
    color: white;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;

const Error = styled.div`
    color: red;
    font-size: 14px;
`;

export function CreateBrandModal({ 
    setVisible,
    brands,
    onSuccess = () => {}
}) {
    const [loading, setLoading] = useState(false);
    const [brandName, setBrandName] = useState('');
    const [error, setError] = useState(null);


    const send = async () => {
        if (brands.find((b) => b.label === brandName)) {
            setError('Brand already exists.')
            return
        }

        setLoading(true);
        setError(null)

        try {
            await api.post('/admin/brands', { name: brandName.trim() })
            NotificationManager.success(`Brand was created.`);
            onSuccess()
            setVisible()
        } catch (err) {
            console.log(err?.response?.data)
            if (err.response.status === 400) setError("We couldn't create the brand.")
        }
        setLoading(false)
    }

    const disabled = !brandName;
    
    const onKeyDown = (event) => {
        if (event.key === 'Enter' && brandName) send()
    }

    return <Modal onClickOutside={() => setVisible(null)}>
        <Box onClick={(e) => e.stopPropagation()}>
            <TopSection>
                <Title>Create new Brand</Title>
            </TopSection>
            <FormSection>
                <Input onKeyDown={onKeyDown} value={brandName} onChange={(e) => setBrandName(e.target.value)} placeholder='New Brand' />
                <Row style={{ gap: 15, justifyContent: 'flex-end', alignItems: 'center', marginTop: 20 }}>
                    <Error>{error}</Error>
                    <div style={{ flex: 1 }} />
                    <ConfirmButton loading={loading} onClick={send} disabled={disabled}>
                        Submit
                    </ConfirmButton>
                </Row>
            </FormSection>
        </Box>
    </Modal>

}
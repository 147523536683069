import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import searchIcon from '../images/search-icon.svg'
import { Row } from './defaults';
import { useLocation } from 'react-router-dom';

const SearchWrapper = styled(Row)`
    align-items: center;
`;

const SearchIcon = styled.img`

`;

const SearchInput = styled.input`
    color: rgba(91, 99, 125, 1);
    font-size: 16px;
    border: none;
    outline: none;
    min-width: 320px;
    border-bottom: 1px solid transparent;
    padding: 13px 0px;
    margin-left: 15px;
    font-family: 'Satoshi-Medium';
    margin-bottom: -5px;

    &:focus {
        border-bottom: 1px solid rgba(79, 89, 240, 1);
    }
`;

export const Search = ({ setQuery, setSkip }) => {

    const [value, setValue] = useState('');
    const timeout = useRef();
    const location = useLocation()
    
    useEffect(() => {
        setValue('')
    }, [location.search])

    const onChangeInput = (e) => {
        setValue(e.target.value);
        if (timeout.current) {
            clearTimeout(timeout.current);
        } 
        timeout.current = setTimeout(() => {
            setQuery(e.target.value)
            setSkip(0)
        }, 3000);
    }

    useEffect(() => {
        return () => {
            if (timeout.current) return clearTimeout(timeout.current)
        }
    }, [])

    return <SearchWrapper>
        <SearchIcon src={searchIcon} alt='Search' />
        <SearchInput value={value} placeholder='Search for a code number, email or order id' onChange={onChangeInput}/>
    </SearchWrapper>

}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row } from "../components/defaults";
import api from "../utils/api";
import { NotificationManager } from "react-notifications";
import { fetchBrands } from "../utils/brand";
import { CreateBrandModal } from "../components/create-brand-modal";
import { ConfirmModal } from "../components/confirm-modal";
import useAuth from "../utils/useAuth";

const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';


const Contents = styled.div`
    padding: 30px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'Satoshi-Medium'
`;

const BrandRowWrapper = styled(Row)`
    height: 80px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -25px;
    color: rgba(0,0,0,.3);
    

    ${props => props.idx % 2 === 0 ? `background: rgba(0,0,0,.05)` : ''};

    #delete-btn {
        display: none;
    }

    &:hover {
        * {
            color: rgba(0,0,0,1);
        }

        #delete-btn {
            display: initial;
        }
    }
    
`;

const BrandName = styled.div`
    flex: 1;
`;

const PillWrapper = styled.div`
    padding: 10px 20px;
    color: white;
    background: ${props => props.background};
    border-radius: 100px;
`;

const PillButton = styled(PillWrapper)`
    background: rgba(79, 89, 240, 1);;
    color: white !important;
    cursor: pointer;
    ${props => props.disabled ? 'opacity: 0.5; pointer-events: none;' : ''};
`;

const DeleteButton = ({ id, ...props }) => {
    return <PillButton id={id} {...props} >Delete</PillButton>
}

const BrandRow = (props) => {
    const [deleting, setDeleting] = useState(false);
    const [deletingVisible, setDeletingVisible] = useState(false);
    const [error, setError] = useState('');
    const { claims } = useAuth();



    const deleteBrand = async () => {
        setError('');
        setDeleting(true)
        try {
            await api.delete('/admin/brands', {
                data: { id: props.value }
            })
            NotificationManager.success('Brand deleted')
            props.onDelete()
            setDeletingVisible(false)
        } catch (err) {
            setError(err?.response?.data?.title || 'An error occurred')
        } finally {
            setDeleting(false)
        }
    }

    return <BrandRowWrapper {...props}>
        {deletingVisible && <ConfirmModal 
        error={error}
        setError={setError}
        setVisible={setDeletingVisible}
        prompt='Do you wish to delete the brand? You can only do it if there are no kits associated with it.'
        onConfirm={deleteBrand} loading={deleting} />}
        <BrandName>{props.label}</BrandName>
        {claims?.role === 'SUPER_ADMIN' && <DeleteButton 
        onClick={() => setDeletingVisible(true)} 
        disabled={deleting} 
        id='delete-btn' />}
    </BrandRowWrapper>
}

export const BrandsPage = () => {

    const [brands, setBrands] = useState([]);
    const [createBrandVisible, setCreateBrandVisible] = useState(false);

    const fetch = () => fetchBrands().then((res) => setBrands(res));

    useEffect(() => {
        fetch()
    }, [])

    const onSuccess = () => fetch()

    return <Contents>
        {createBrandVisible && <CreateBrandModal 
        onSuccess={onSuccess}
        brands={brands}
        setVisible={setCreateBrandVisible} />}
        <Row style={{ marginBottom: 30 }}>
            <TopTitle>Brands</TopTitle>
            <div style={{ flex: 1 }} />
            <PillButton onClick={() => setCreateBrandVisible(true)}>Create brand</PillButton>
        </Row>
        {brands.map((brand, i) => 
        <BrandRow 
        idx={i} onDelete={fetch}
        key={brand.value} 
        {...brand} />)}
    </Contents>

}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Modal } from './modal';
import styled from 'styled-components';
import { Button, Column } from './defaults';
import { useEffect } from 'react';
import { fetchBrands } from '../utils/brand';
import { DropdownInput } from './dropdown-input';
import { NotificationManager } from 'react-notifications';
import api from '../utils/api';

const Box = styled.div`
    border-radius: 24px;
    background: white;
`;

const TopSection = styled.div`
    padding: 24px 40px;
    border-bottom: 1px solid rgba(219, 219, 219, 1);
`;

const FormSection = styled(Column)`
    padding: 32px 40px;
    gap: 20px;
    width: 400px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;


const PrButton = styled(Button)`
    background: rgba(79, 89, 240, 1);
    color: white;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;

const Error = styled.div`
    color: red;
    font-size: 14px;
`;


export function ChangeBrandModal({ 
    barcode,
    setVisible,
    fetchKits,
    data,
}) {

    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(false);
    const [brand, setBrand] = useState();
    const [error, setError] = useState('');

    const thisData = data.find((d) => d.code === barcode);
    const currentBrand = brands?.find((b) => b?.label === thisData?.brand)
    
    useEffect(() => {
        fetchBrands().then((res) => setBrands(res))
    }, []);

    useEffect(() => {
        if (currentBrand) setBrand(currentBrand)
    }, [currentBrand])

    const send = async () => {
        try {
            setLoading(true)
            api.post('/admin/kit/update', { barcode, brand_id: brand.value})
            setVisible(null)
            NotificationManager.success('Brand updated')
            fetchKits()
        } catch (err) {
            setError('An error occurred')
        } finally {
            setLoading(false)
        }
    }

    return <Modal onClickOutside={() => setVisible(null)}>
        <Box onClick={(e) => e.stopPropagation()}>
            <TopSection>
                <Title>Assign a new brand</Title>
            </TopSection>
            <FormSection>
                <Column>
                    {brands ? <DropdownInput 
                    value={brand} 
                    onChange={setBrand} 
                    options={brands} 
                    label='Brands' /> : 
                    <div style={{ color: 'rgba(0,0,0,.4)' }}>Loading Brands</div>}
                    {error && <Error>{error}</Error>}
                    <PrButton 
                    style={{ alignSelf: 'flex-end', marginTop: 15}} 
                    loading={loading} onClick={send} >
                    Submit
                    </ PrButton>
                </Column>
            </FormSection>
        </Box>
    </Modal>

}
import React, { useEffect, useState } from 'react';
import { Modal } from './modal';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import Select from 'react-dropdown-select';
import useAuth from '../utils/useAuth';
import api from '../utils/api';
import loadingIcon from '../images/3-dots-move.svg'
import { fetchBrands } from '../utils/brand';

const Box = styled.div`
    border-radius: 24px;
    background: white;
`;

const TopSection = styled.div`
    padding: 24px 40px;
    border-bottom: 1px solid rgba(219, 219, 219, 1);
`;

const FormSection = styled(Column)`
    padding: 32px 40px;
    gap: 20px;
    width: 400px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;

const InputWrapper = styled(Column)`
    label {
        color: rgba(91, 99, 125, 1);
        font-size: 15px;
        margin-bottom: 10px;
    }
    input {
        font-family: 'Satoshi-Regular';
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        font-size: 16px;
        outline: none;

        &:disabled {
            opacity: 0.5
        }
    } 
`;

const DropdownInputWrapper = styled(Column)`
    label {
        color: rgba(91, 99, 125, 1);
        font-size: 15px;
        margin-bottom: 10px;
    }
    * {
        outline: none;
        font-family: 'Satoshi-Regular';
    }
    .react-dropdown-select {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        outline: none;
    } 
    .react-dropdown-select-input{
        font-size: 16px;
        padding: 0px;
        font-family: 'Satoshi-Regular';
    }
`;

const Input = ({ label, ...inputProps }) => {

    return <InputWrapper>
        <label>{label}</label>
        <input {...inputProps} />
    </InputWrapper>

}

const DropdownInput = ({ label, options, value, onChange, ...inputProps }) => {
    
    return <DropdownInputWrapper>
        <label>{label}</label>
        <Select options={options} values={value ? [value] : []} 
        onChange={(e) => {
            onChange(e[0])
        }}
        {...inputProps} />
    </DropdownInputWrapper>

}

function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

const Button = styled.div`
    color: rgba(79, 89, 240, 1);
    cursor: pointer;
    font-size: 16px;
`;

const PrimaryButton = styled.div`
    background: rgba(79, 89, 240, 1);
    color: white;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;

const Loading = styled.div`
`;

const Error = styled.div`
    color: red;
    font-size: 15px;
`;

export default function EditAdmin({ 
    setVisible, 
    defaults = {}, 
    confirm,
    emailDisabled,
    modalTitle = 'Edit Admin'
}) {

    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(false);
    const [first_name, setFirstName] = useState(defaults?.first_name || '');
    const [last_name, setLastName] = useState(defaults?.last_name || '');
    const [email, setEmail] = useState(defaults?.contact?.email || '');
    const [brand, setBrand] = useState(!!defaults?.brand ? {label: defaults?.brand?.name, value: defaults?.brand?.id} : null);
    const [role, setRole] = useState(!!defaults?.trume_role ? {label: defaults?.trume_role, value: defaults?.trume_role} : null);
    const [id, setId] = useState(defaults?.id || {});
    const [error, setError] = useState(null);
    const roles = [
        {
            label: 'ADMIN',
            value: 'ADMIN'
        },
        {
            label: 'TECHNICIAN',
            value: 'TECHNICIAN'
        }
    ]
    
    const { claims } = useAuth();

    const [loadingBrands, setLoadingBrands] = useState(true);

    const _fetchBrands = async () => {
        const result = await fetchBrands()
        setBrands(result)
        setLoadingBrands(false)
    }

    useEffect(() => {
        if (!!claims && claims?.brand === 1) _fetchBrands()
        else if (!!claims) setLoadingBrands(false)
    }, [claims])

    const onClick = async () => {
        if (!emailDisabled && !validateEmail(email)) return setError('Please enter a valid email.')
        setError('');

        setLoading(true);
        try {
            await confirm({
                first_name,
                last_name,
                email,
                brand: brand.value,
                role: role.value,
                id,
            })
        } catch (err) {
            //console.log('EE', err)
            if (err.response.status === 400) {
                setError(err.response.data.title)
            }
        }

        
        setLoading(false)
    }
    
    const disabled = !first_name || !last_name || (!email && !emailDisabled) || !brand || loading;


    if (loadingBrands) return <Modal>
        <img src={loadingIcon} alt='loading' />
    </Modal>

    
    return <Modal onClickOutside={() => setVisible(null)}>
        <Box onClick={(e) => e.stopPropagation()}>
            {!loadingBrands && <>
            <TopSection>
                <Title>{modalTitle}</Title>
            </TopSection>
            <FormSection>
                <Input label='First Name' value={first_name} onChange={(e) => setFirstName(e.target.value)} />
                <Input label='Last Name' value={last_name} onChange={(e) => setLastName(e.target.value)} />
                <Input disabled={emailDisabled} label='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                {claims?.brand === 1 && <DropdownInput label='Brand' options={brands} value={brand} onChange={(e) => setBrand(e)} />}
                {claims?.brand === 1 && <DropdownInput label='Role' options={roles} value={role} onChange={(e) => setRole(e)} />}
                
                <Row style={{ gap: 15, alignItems: 'center', justifyContent: 'flex-end', marginTop: 20 }}>
                    <Error>{error}</Error>
                    <div style={{ flex: 1 }} />
                    <Button onClick={() => setVisible(null)}>Cancel</Button>
                    <PrimaryButton disabled={disabled} onClick={onClick}>Confirm</PrimaryButton>
                </Row>
            </FormSection>
            </>}
            
        </Box>
    </Modal>

}
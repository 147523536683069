const convertToCSV = (jsonObject) => {
  const headers = Object.keys(jsonObject[0]);
  const csvRows = [];

  csvRows.push(headers.join(','));

  jsonObject.forEach((item) => {
    const values = headers.map((header) => {
      const escapeValue = item[header] ? `${item[header]}` : '';
      return escapeValue.replace(/"/g, '""');
    });
    csvRows.push(values.join(','));
  });

  return csvRows.join('\n');
};

export const downloadCSV = (jsonData, fileName) => {
  const csv = convertToCSV(jsonData);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
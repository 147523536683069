import React from 'react';
import styled from 'styled-components';
import { Column } from './defaults';
import Select from 'react-dropdown-select';


const DropdownInputWrapper = styled(Column)`
    label {
        color: rgba(91, 99, 125, 1);
        font-size: 15px;
        margin-bottom: 10px;
    }
    * {
        outline: none;
        font-family: 'Satoshi-Regular';
    }
    .react-dropdown-select {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        outline: none;
    } 
    .react-dropdown-select-input{
        font-size: 16px;
        padding: 0px;
        font-family: 'Satoshi-Regular';
    }
`;

export const DropdownInput = ({ label, options, value, onChange, ...inputProps }) => {
    
    return <DropdownInputWrapper>
        <label>{label}</label>
        <Select options={options} values={value ? [value] : []} 
        onChange={(e) => {
            onChange(e[0])
        }}
        {...inputProps} />
    </DropdownInputWrapper>

}

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Modal } from './modal';
import styled from 'styled-components';
import { Button, Column, Row } from './defaults';

const Box = styled.div`
    border-radius: 24px;
    background: white;
`;

const TopSection = styled.div`
    padding: 24px 40px;
    border-bottom: 1px solid rgba(219, 219, 219, 1);
`;

const FormSection = styled(Column)`
    padding: 32px 40px;
    gap: 20px;
    width: 400px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;


const ConfirmButton = styled(Button)`
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};

    ${props => !props.secondary ? 
        `background: rgba(79, 89, 240, 1);
        color: white !important;` :
        `background: white;
        color: gray !important;
        margin-left: -15px`
    }
`;

const Error = styled.div`
    color: red;
    font-size: 14px;
`;

export function ConfirmModal({ 
    onConfirm,
    setVisible,
    loading,
    error,
    setError,
    prompt
}) {

    const onSetVisible = (value) => {
        if (error) setError(false)
        setVisible(value)
    }

    return <Modal onClickOutside={() => onSetVisible(null)}>
        <Box onClick={(e) => e.stopPropagation()}>
            <TopSection>
                <Title>Confirm Action</Title>
            </TopSection>
            <FormSection>
                <div style={{ color: 'rgba(0,0,0,.8)'}}>{prompt}</div>
                <Row style={{ gap: 15, alignItems: 'center', marginTop: 20 }}>
                    <ConfirmButton secondary onClick={() => onSetVisible(false)} disabled={loading}>
                        Cancel
                    </ConfirmButton>
                    <div style={{ flex: 1 }} />
                    <ConfirmButton loading={loading} onClick={onConfirm} disabled={loading}>
                        Confirm
                    </ConfirmButton>
                </Row>
                {error && <Error style={{ color: 'red', alignSelf: 'flex-end' }}>{error}</Error>}
            </FormSection>
        </Box>
    </Modal>

}
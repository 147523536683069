/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Row } from "../components/defaults";
import { RegisteredIcon } from "../images/registered";
import { UnassignedIcon } from "../images/unassigned";
import { InLabIcon } from "../images/in-lab";
import { ProcessedIcon } from "../images/processed";
import { FlaggedIcon } from "../images/flagged";
import useAuth from "../utils/useAuth";
import { Search } from "../components/search";
import { Table, TableContext, TableContextWrapper } from "../components/table";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchBrands, fetchLaboratory, fetchTests } from "../utils/brand";
import { KitCommandContextWrapper, KitCommandContext, KitCommands, BrandSelector } from "../components/kit-commands";

const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';
const UNSELECTED_COLOR = 'rgba(91, 99, 125, 1)';

const statusBgColors = {
    'REGISTERED': 'rgba(255, 77, 109, 1)',
    'PROCESSED': 'rgba(79, 222, 119, 1)',
    'UNASSIGNED': 'rgba(255, 77, 109, 1)',
    'IN_LAB': '#FEAF1E',
    'FLAGGED': 'rgba(255, 77, 109, 1)',
    'NEW_ORDER': 'rgba(53, 66, 255, 1)',
}

const Contents = styled.div`
    padding: 30px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 31px;
    margin-bottom: 23px;
    font-family: 'Satoshi-Medium'
`;


const TopRow = styled(Row)`

`;

const TabTitleWrapper = styled(Row)`
    padding:16px 22px;
    align-items: center;
    cursor: pointer;
    border-bottom: ${props => props.selected ? `1px solid ${SELECTED_COLOR}` : '1px solid transparent'};
`;

const TabTitleText = styled.div`
    font-size: 17px;
    margin-left: 8px;
    color: ${props => props.selected ?  SELECTED_COLOR : UNSELECTED_COLOR };
`;

const Count = styled.div`
    color: white;
    background: rgba(79, 89, 240, 1);
    font-size: 12px;
    border-radius: 4px;
    padding: 3px;
    width: 16px;
    height: 16px;
    text-align: center;
    margin-left: 10px;
`;

const TabTitle = ({ selected = false, count, Icon, title, onClick = () => {} }) => {
    return <TabTitleWrapper selected={selected} onClick={onClick}>
        <Icon fill={selected ? SELECTED_COLOR : UNSELECTED_COLOR} />
        <TabTitleText selected={selected}>{title}</TabTitleText>
        {count > 0 && <Count>{count}</Count>}
    </TabTitleWrapper>
}

const PillWrapper = styled.div`
    padding: 10px 20px;
    color: white;
    background: ${props => props.background};
    border-radius: 100px;
`;


const selectedColumns = {
    'UNASSIGNED': ['code', 'shopify_order_number', 'status', 'test', 'brand', 'laboratory', 'commands'],
    'REGISTERED': ['code', 'status',  'test', 'brand', 'laboratory', 'commands'],
    'IN_LAB': ['code', 'status',  'test', 'brand', 'laboratory', 'commands', 'laboratory_number', 'chronological_age', 'sample_date'],
    'PROCESSED': ['code', 'status', 'test', 'brand', 'laboratory', 'commands', 'laboratory_number', 'chronological_age', 'sample_date', 'scanned_at', 'biological_age', 'methylation_level'],
    'FLAGGED': ['code', 'status', 'test', 'brand', 'laboratory', 'commands', 'laboratory_number', 'chronological_age', 'sample_date', 'scanned_at', 'biological_age', 'methylation_level'],
    'NEW_ORDER': ['shopify_order_number', 'shopify_order_date', 'status', 'test', 'brand', 'laboratory', 'commands']
}

const Total = styled.div`
    align-self: flex-end;
    margin-bottom: 15px;
    color: rgba(91, 99, 125, 1);
    margin-right: 20px;
    border-radius: 100px;
    border: 1px solid rgba(91, 99, 125, 1);
    padding: 1px 10px;
`;

export const OverviewContents = () => {

    const [selectedTab, setSelectedTab] = useState('REGISTERED');
    const [flaggedCount, setFlaggedCount] = useState([]);
    const navigate = useNavigate()
    const location = useLocation();
    const { claims } = useAuth();

    
    const {
        query,
        setQuery,
        skip,
        limit,
        setSkip,
        fieldSort,
        fieldQuery,
        fetchKits,
        data,
        loading,
        count
    } = useContext(TableContext);
    
    const {
        setSelectedBrandKit
    } = useContext(KitCommandContext)
    
    const columns = (id) => [
        { id: 'shopify_order_number', title: 'Order Number'},
        { id: 'shopify_order_date', title: 'Order date', dateField: true},
        { id: 'code', title: 'Code', disableQuery: true },
        { id: 'laboratory_number', title: 'Lab. Number' },
        { id: 'status', title: 'Status', disableQuery: true, disableSorting: true, formatter: (values) => <PillWrapper background={statusBgColors[values['status']]}>{values['status']}</PillWrapper> },
        { id: 'test', title: 'Test', fetchOptions: fetchTests, disableSorting: true },
        { id: 'chronological_age', title: 'Chron. Age', numberOnly: true },
        { id: 'biological_age', title: 'Biol. Age', numberOnly: true },
        { id: 'methylation_level', title: 'Methyl. Level', numberOnly: true  },
        { id: 'peer_biological_age_score', title: 'Peer. Score', numberOnly: true  },
        { id: 'sample_date', title: 'Sample Date', dateField: true},
        { id: 'brand', title: 'Brand', fetchOptions: fetchBrands, formatter: (values) => 
            <BrandSelector {...values} setVisible={setSelectedBrandKit} claims={claims} />, disableSorting: true },
        { id: 'laboratory', title: 'Laboratory', fetchOptions: fetchLaboratory, disableSorting: true },
        { id: 'scanned_at', dateField: true, title: 'Received Date'},
        { id: 'commands', title: '', formatter: (values) => <KitCommands  id={id} {...values} />}
    ]

    useEffect(() => {
        const tab = new URLSearchParams(location.search).get('tab');
        setSelectedTab(tab.toLocaleUpperCase())
        setQuery('')
    }, [location.search])

    const sharedProps = (tabName) => ({
        onClick: () => {
            navigate(`/dashboard/overview?tab=${tabName.toLowerCase()}`)
        },
        selected: tabName === selectedTab,
    })


    useEffect(() => {
        if (claims) fetchKits(selectedTab);
    }, [selectedTab, claims, limit, query, skip, fieldQuery, fieldSort])


    return <Contents>
        <Row>
            <TopTitle>Overview</TopTitle>
            <div style={{ flex: 1 }} />
        </Row>
        <TopRow>
            { claims?.brand === 1 && <TabTitle {...sharedProps('NEW_ORDER')} Icon={UnassignedIcon} title='New Order'/> }
            <TabTitle {...sharedProps('UNASSIGNED')} Icon={UnassignedIcon} title='Unassigned'/>
            <TabTitle {...sharedProps('REGISTERED')} Icon={RegisteredIcon} title='Registered'/>
            <TabTitle {...sharedProps('IN_LAB')} Icon={InLabIcon} title='In lab'/>
            <TabTitle {...sharedProps('PROCESSED')} Icon={ProcessedIcon} title='Processed' />
            <TabTitle {...sharedProps('FLAGGED')} Icon={FlaggedIcon} title='Flagged' count={flaggedCount} />
            <div style={{ flex: 1 }} />
            <Total>Total kits: {count}</Total>
            <Search setSkip={setSkip} setQuery={setQuery}/>
        </TopRow>
        <Table data={data} selectedColumns={selectedColumns[selectedTab]} columns={columns} loading={loading} />
    </Contents>

}

export const Overview = () => {

    return <div> 
        <TableContextWrapper>
            <KitCommandContextWrapper>
                <OverviewContents />
            </KitCommandContextWrapper>
        </TableContextWrapper>  
    </div>

}